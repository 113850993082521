import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface OrganizationState {
  orgId: string;
}

const initialState: OrganizationState = {
  orgId: ""
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setActiveOrganization: (
      state,
      action: PayloadAction<OrganizationState["orgId"]>
    ) => {
      return {
        ...state,
        orgId: action.payload
      };
    }
  }
});

export const { setActiveOrganization } = organizationSlice.actions;

export default organizationSlice.reducer;
