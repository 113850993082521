import { createContext, CSSProperties, ReactNode } from "react";

export type LayerType = {
  key: string;
  component: ReactNode;
  options: LayerOptions;
};

export type LayerOptions = {
  /**
   * callback function when the layer is closed.
   */
  onClose?: () => void;
  /**
   * [default : true] If false, the layer won't automatically hide when another layer is shown.
   */
  autoHide?: boolean;
  /**
   * override the dimmed css.
   */
  dimmdCss?: CSSProperties;
};

type LayerContextType = {
  layers: LayerType[];
  push: (params: LayerType) => void;
  delete: (key: string) => void;
  clear: () => void;
};

const defaultValue = {
  layers: [] as LayerType[],
  push: () => {
    throw new Error("The push function is not implemented.");
  },
  delete: () => {
    throw new Error("The delete function is not implemented.");
  },
  clear: () => {
    throw new Error("The clear function is not implemented.");
  }
};

export const LayerContext = createContext<LayerContextType>(defaultValue);
