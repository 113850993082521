import { isNumber } from "lodash";

const parseEnvironmentFromUrl = () => {
  const url = window.location.origin;
  if (url.includes("dev") || window.location.hostname === "localhost") {
    return "dev.";
  }
  if (url.includes("qa")) {
    return "qa.";
  }
  return "";
};
function getDomainType() {
  const domain = parseEnvironmentFromUrl();

  if (domain.includes("dev")) {
    return "dev";
  }

  if (domain.includes("qa")) {
    return "qa";
  }

  return "prod";
}

const getPortalHostName =
  typeof window === "undefined" || window.location.hostname === "localhost"
    ? `dev.platform.hanwhavision.cloud`
    : `${parseEnvironmentFromUrl()}platform.hanwhavision.cloud`;

const getHostName =
  typeof window === "undefined" || window.location.hostname === "localhost"
    ? "dev.sightmind.hanwhavision.cloud"
    : `${parseEnvironmentFromUrl()}sightmind.hanwhavision.cloud`;

function getURL() {
  return getHostName;
}

function getPortalURL() {
  return getPortalHostName;
}

/**
 * To show + 1 number of camera channel
 * @param channel
 * @returns
 */
const displayChannelNo = (channel: number) =>
  isNumber(channel) ? channel + 1 : channel;

/* eslint-disable no-bitwise */
const generateUUID = () => {
  let dt = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export { getURL, getPortalURL, getDomainType, displayChannelNo, generateUUID };
