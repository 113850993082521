import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { LayerContext } from "./LayerContext";
import * as S from "./Layer.styled";

export const LayerPortal = () => {
  const { layers, clear } = useContext(LayerContext);

  if (layers.length === 0) {
    return null;
  }

  return ReactDOM.createPortal(
    <S.Container>
      {layers.map((layer) => (
        <React.Fragment key={layer.key}>
          <S.Dimmed onClick={clear} style={layer.options.dimmdCss} />
          <S.Content>{layer.component}</S.Content>
        </React.Fragment>
      ))}
    </S.Container>,
    document.getElementById("portal-layer") as Element
  );
};
