import { createSlice } from "@reduxjs/toolkit";
import { NotificationType } from "@queries/Notifications";

const initialState: NotificationType = {
  id: "",
  device: {
    id: "",
    name: "",
    channel: 0,
    ruleName: "",
    ruleIndex: 0
  },
  eventType: "NONE",
  status: "DETECTED",
  thumbnail: "",
  timestamp: "",
  type: "",
  widgets: [],
  read: false,
  eventName: ""
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNewNotification: (_, action) => {
      return action.payload;
    }
  }
});

export const { setNewNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
