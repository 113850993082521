import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { GraphStatistics } from "src/pages/report/Interfaces";

interface ReportState {
  reportId: string;
  graphStatistics: GraphStatistics | undefined;
}

const initialState: ReportState = {
  reportId: "",
  graphStatistics: undefined
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setActiveReport: (
      state,
      action: PayloadAction<ReportState["reportId"]>
    ) => {
      return {
        ...state,
        reportId: action.payload
      };
    },
    setActiveReportGraphStatistics: (
      state,
      action: PayloadAction<ReportState["graphStatistics"]>
    ) => {
      return {
        ...state,
        graphStatistics: action.payload
      };
    }
  }
});

export const { setActiveReport, setActiveReportGraphStatistics } =
  reportSlice.actions;

export default reportSlice.reducer;
