import * as amplitude from "@amplitude/analytics-browser";
import { getDomainType } from "@utils/Common";

function setUserId(userId: string) {
  amplitude.setUserId(userId);
}

function reset() {
  amplitude.reset();
}

function logIntoSightMind() {
  amplitude.track("Into_SightMind");
}

function getAmplitudeLicenseKey() {
  if (getDomainType() === "prod") {
    return process.env.REACT_APP_AMPLITUDE_PROD_API_KEY;
  }
  return process.env.REACT_APP_AMPLITUDE_API_KEY;
}

const Amplitude = {
  getAmplitudeLicenseKey,
  setUserId,
  reset,
  logIntoSightMind
};
export default Amplitude;
