import { EventNotificationProps } from "@components/event-notification/Interfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { countBy } from "lodash";

type EventState = EventNotificationProps[];

const initialState: EventState = [];

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEventNotification: (
      state: EventState,
      action: PayloadAction<EventState>
    ): EventState => {
      const events = [...state, ...action.payload];

      const eventsCount = countBy(events, "widgetId");
      return events.map((event) => ({
        ...event,
        eventCount: eventsCount[event.widgetId] || 0
      }));
    },
    deleteEventNotification: (
      state: EventState,
      action: PayloadAction<string>
    ) => {
      return state.filter((event) => event.widgetId !== action.payload);
    },
    deleteAllEventNotification: () => []
  }
});

export const {
  setEventNotification,
  deleteEventNotification,
  deleteAllEventNotification
} = eventSlice.actions;

export default eventSlice.reducer;
