import React, { CSSProperties } from "react";
import { BarLoader } from "react-spinners";
import { IconLoader } from "@resources/icons/Icons";
import * as S from "./Loading.styled";

interface LoadingProps {
  type?: "clip" | "bar";
  position?: CSSProperties["position"];
}
export const Loading = (props: LoadingProps) => {
  const { type = "clip", position = "fixed" } = props;

  return (
    <S.StyledContainer>
      <S.StyledLoader position={position}>
        {type === "clip" ? (
          <S.Loader>
            <IconLoader />
          </S.Loader>
        ) : (
          <BarLoader loading color="#f47320" height={2} />
        )}
      </S.StyledLoader>
    </S.StyledContainer>
  );
};
