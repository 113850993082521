import { Lang } from "@custom-types/Types";
import { LANG } from "@resources/Constants";
import English from "./En.json";
import Korean from "./Ko.json";

type LangObject = {
  [key: string]: string | LangObject;
};

export const messages: { [key in Lang]: LangObject } = {
  [LANG.EN]: English,
  [LANG.KO]: Korean
};

export const flattenMessages = (nestedMessages: LangObject, prefix = "") => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === "string") {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }
    return messages;
  }, {});
};
