import React, { useEffect } from "react";
import { useAppSelector } from "@configs/Redux";
import * as amplitude from "@amplitude/analytics-browser";
import Amplitude from "@utils/Amplitude";

export default function AmplitudeProvider({
  children
}: {
  children?: React.ReactNode;
}) {
  const { userId } = useAppSelector((state) => state.system);
  useEffect(() => {
    if (userId) {
      Amplitude.setUserId(userId);
    }
  }, [userId]);

  useEffect(() => {
    const key = Amplitude.getAmplitudeLicenseKey();
    if (key) {
      amplitude.init(key, {
        defaultTracking: true
      });
      Amplitude.logIntoSightMind();
    }
  }, []);

  return children;
}
