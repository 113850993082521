import { ThemeType } from "@custom-types/Types";
import { THEME_TYPE } from "@resources/Constants";
import { useEffect, useState } from "react";

export const useThemeDetector = (): ThemeType => {
  const getCurrentTheme = () =>
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());

  const listener = (e: MediaQueryListEvent) => {
    setIsDarkTheme(e.matches);
  };

  useEffect(() => {
    const darkThemeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );

    darkThemeMediaQuery.addEventListener("change", listener);
    return () => darkThemeMediaQuery.removeEventListener("change", listener);
  }, []);

  return isDarkTheme ? THEME_TYPE.DARK : THEME_TYPE.LIGHT;
};
