import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Loading } from "@components/common/loading";
import useUserPermissions from "@hooks/useUserPermissions";

const SystemManagement = lazy(
  () => import("../pages/setup/system/system-management")
);

const Dashboard = lazy(() => import("../pages/dashboard"));
const Event = lazy(() => import("../pages/event"));
const Report = lazy(() => import("../pages/report"));
const DeviceSetup = lazy(() => import("../pages/setup/device/device-setup"));
const User = lazy(() => import("../pages/setup/user/user"));
const Roles = lazy(() => import("../pages/setup/user/roles"));
const Page403 = lazy(() => import("../pages/error-pages/page403"));
const Page404 = lazy(() => import("../pages/error-pages/page404"));
const Setup = lazy(() => import("src/pages/setup"));
const ProtectPage = lazy(() => import("@components/page-wrapper/ProtectPage"));

export const Router = () => {
  const { isUserAccessibleEventReport, isUserAccessibleSettings } =
    useUserPermissions();
  return (
    <Suspense
      fallback={
        <div style={{ padding: "52px 0 0 64px" }}>
          <Loading type="clip" />
        </div>
      }
    >
      <Routes>
        <Route element={<ProtectPage />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboards/:urlDashboardId" element={<Dashboard />} />
          <Route
            path="/events"
            element={isUserAccessibleEventReport() ? <Event /> : <Page403 />}
          />
          <Route
            path="/reports"
            element={isUserAccessibleEventReport() ? <Report /> : <Page403 />}
          />
          <Route
            path="/settings"
            element={isUserAccessibleSettings() ? <Setup /> : <Page403 />}
          >
            <Route path="/settings/devices/devices" element={<DeviceSetup />} />
            <Route
              path="/settings/system/system-management"
              element={<SystemManagement />}
            />
            {/* <Route path="/setup/user/admin" element={<Admin />} /> */}
            <Route path="/settings/users/users" element={<User />} />
            <Route path="/settings/users/roles" element={<Roles />} />
          </Route>
          <Route path="/forbidden" element={<Page403 />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
