import styled, { CSSProperties } from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledLoader = styled.div<{ position: CSSProperties["position"] }>`
  position: ${({ position }) => position};
  top: 50%;
`;

export const Loader = styled.div`
  position: relative;
  margin: 0 auto;
  width: 80px;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  > svg {
    animation: rotate 1.33s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  circle:nth-of-type(1) {
    stroke: rgba(255, 255, 255, 0.2);
  }
  circle:nth-of-type(2) {
    stroke: var(--orange-4);
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -21px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
`;
