import { useAppSelector } from "@configs/Redux";

export default function useUserPermissions() {
  const { userPermissions } = useAppSelector((state) => state.system);

  const isUserDashboardEditable = () => {
    return (
      userPermissions.dashboard.dashboardAddEditDelete &&
      userPermissions.dashboard.widgetAddDeleteShowHide
    );
  };

  const isUserAccessibleEventReport = () => {
    return (
      userPermissions.event.eventSearch && userPermissions.report.reportView
    );
  };
  const isUserAccessibleSettings = () => {
    return (
      userPermissions.setting.userManagement &&
      userPermissions.setting.deviceManagement
    );
  };

  const isUserDashboardSequenceEnabled = () => {
    return userPermissions.dashboard.dashboardSequence;
  };

  return {
    isUserDashboardEditable,
    isUserAccessibleEventReport,
    isUserAccessibleSettings,
    isUserDashboardSequenceEnabled
  };
}
