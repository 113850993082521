import { useAppSelector } from "@configs/Redux";
import { Router } from "@configs/Router";
import { ThemeType } from "@custom-types/Types";
import { useThemeDetector } from "@hooks/useThemeDetector";
import { flattenMessages, messages } from "@languages/index";
import { COLOR_MODE, LANG } from "@resources/Constants";
import { GlobalStyles } from "@themes/GlobalStyles";
import { getTheme } from "@themes/index";
import React from "react";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import { LayerPortal } from "@components/common/layer/LayerPortal";
import { PageWrapperStyled } from "./PageWrapper.styled";

export function PageWrapper(): JSX.Element {
  const systemTheme = useThemeDetector();
  const { lang, colorMode } = useAppSelector((state) => state.system);

  return (
    <IntlProvider
      locale={lang}
      defaultLocale={LANG.EN}
      messages={flattenMessages(messages[lang])}
    >
      <ThemeProvider
        theme={getTheme(
          colorMode === COLOR_MODE.SYSTEM
            ? systemTheme
            : (colorMode as ThemeType)
        )}
      >
        <GlobalStyles />

        <PageWrapperStyled>
          <Router />
          <LayerPortal />
        </PageWrapperStyled>

        <div style={{ display: "none" }}>
          Build Version: {process.env.BUILD_VERSION_DATE}
        </div>
      </ThemeProvider>
    </IntlProvider>
  );
}
