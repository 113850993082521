import { Union } from "@custom-types/Types";
import { FORMAT, LICENSE_REMAINING_DAYS } from "@resources/Constants";
import { isNaN, isNil } from "lodash";
import moment from "moment";

export const classNames = (data: { [key: string]: boolean | undefined }) => {
  return Object.keys(data)
    .filter((key: string) => data[key])
    .join(" ");
};

export const channelLabeling = (
  value: string | number | boolean | undefined
): string => {
  return !isNil(value) && !isNaN(Number(value)) ? `${Number(value) + 1}` : "-";
};

export const convertDataTime = (
  timestamp: string | number | undefined,
  format: Union<typeof FORMAT>
): string => {
  return moment(new Date(Number(timestamp))).format(format);
};

export const convertHexWithOpacity = (hex: string, opacity: number) => {
  if (opacity < 0 || opacity > 100) {
    throw new Error("opacity value must be between 0 and 100");
  }

  const alphaHex = Math.round((opacity / 100) * 255)
    .toString(16)
    .toUpperCase()
    .padStart(2, "0");

  return `${hex}${alphaHex}`;
};

export const convertHexToRgb = (hex: string, space = ",") => {
  const isValid = /^#([0-9a-fA-F]{3}){1,2}$/.test(hex);
  if (isValid) {
    const rgb = hex
      .replace(
        /^#([0-9a-fA-F]{2}|[0-9a-fA-F]{1})([0-9a-fA-F]{2}|[0-9a-fA-F]{1})([0-9a-fA-F]{2}|[0-9a-fA-F]{1})$/,
        "$1 $2 $3"
      )
      .split(" ");
    return rgb
      .map((item) => parseInt(`${item}${item.length === 1 ? item : ""}`, 16))
      .join(`${space} `);
  }
  return "";
};

export const isLicenseExpiringWithinDays = (
  licenseData: string | number | undefined
) => {
  const currentTime = moment().utc().valueOf();
  const remainingDaysInMillis = LICENSE_REMAINING_DAYS * 24 * 60 * 60 * 1000;
  const expiryTime = moment.utc(licenseData).valueOf();

  if (expiryTime <= currentTime) {
    // 만료일이 현재 시간 이전이면, 무조건 true 리턴
    return true;
  }

  // 만료일이 현재 시간 이후이며, 30일 이내인지 확인하는 로직 추가
  return expiryTime - currentTime <= remainingDaysInMillis;
};

export const convertSecondsToMinutesAndSeconds = (
  seconds: number
): { minutes: number; seconds: number } => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return { minutes, seconds: remainingSeconds };
};

export const snakeToCamel = (str: string): string => {
  const words = str.toLocaleLowerCase().split("_");
  const camelCase = words
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      const firstLetterCap = word.charAt(0).toUpperCase();
      const remainingLetters = word.slice(1);
      return firstLetterCap + remainingLetters;
    })
    .join("");

  return camelCase;
};

export const subtractDaysFromUnixTimestamp = (
  unixTimestamp: number,
  days: number
): number => {
  const millisecondsInADay = 86400000; // 밀리 세컨드로 변환
  const millisecondsToSubtract = days * millisecondsInADay;

  const newUnixTimestamp = unixTimestamp - millisecondsToSubtract;

  return newUnixTimestamp;
};
