import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  animation: ${fadeIn} 0.3s ease-out;
`;

export const Dimmed = styled.div`
  position: absolute;
  background-color: rgba(12, 12, 12, 0.8);
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  position: absolute;
  top: 0px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;
