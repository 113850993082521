import { WidgetType } from "@custom-types/Types";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_DASHBOARD_SEQUENCE_CONVERSION_TIME } from "@resources/Constants";

interface DashboardInfo {
  id: string;
  name: string;
}

interface DashboardState {
  activatedDashboardGroupName?: string | null;
  activatedDashboardName: string;
  dashboardActivated?: string;
  dashboardSequence: DashboardSequence;
  widgetSelected?: string;
  widgetHideSelected?: boolean;
  widgetResized?: boolean;
  dashboards: DashboardInfo[];
  widgetSelectedType?: WidgetType;
}

interface DashboardSequence {
  toggle: boolean;
  conversionTime: number;
  items: string[];
}

const initialState: DashboardState = {
  activatedDashboardGroupName: "",
  activatedDashboardName: "",
  dashboardActivated: "",
  dashboardSequence: {
    toggle: false,
    conversionTime: DEFAULT_DASHBOARD_SEQUENCE_CONVERSION_TIME,
    items: []
  },
  widgetHideSelected: false,
  widgetResized: false,
  dashboards: [],
  widgetSelectedType: undefined
};

// TODO 삭제 후 redux로 옮길 예정
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setActiveDashboardId: (
      state,
      action: PayloadAction<DashboardState["dashboardActivated"]>
    ) => {
      return {
        ...state,
        dashboardActivated: action.payload
      };
    },
    showModalDeleteWidget: (
      state,
      action: PayloadAction<DashboardState["widgetSelected"]>
    ) => {
      return {
        ...state,
        widgetSelected: action.payload
      };
    },
    setSelectWidgetType: (state, action: PayloadAction<WidgetType>) => {
      return {
        ...state,
        widgetSelectedType: action.payload
      };
    },
    showModalHideWidget: (
      state,
      action: PayloadAction<DashboardState["widgetHideSelected"]>
    ) => {
      return {
        ...state,
        widgetHideSelected: action.payload
      };
    },
    setDashboards: (state, action: PayloadAction<DashboardInfo[]>) => {
      return {
        ...state,
        dashboards: action.payload
      };
    },
    updateDashboardSequence: (
      state,
      action: PayloadAction<DashboardState["dashboardSequence"]>
    ) => {
      return {
        ...state,
        dashboardSequence: action.payload
      };
    },
    setActivatedDashboardGroupName: (
      state,
      action: PayloadAction<DashboardState["activatedDashboardGroupName"]>
    ) => {
      return {
        ...state,
        activatedDashboardGroupName: action.payload
      };
    },
    setActivatedDashboardName: (
      state,
      action: PayloadAction<DashboardState["activatedDashboardName"]>
    ) => {
      return {
        ...state,
        activatedDashboardName: action.payload
      };
    },
    setWidgetResized: (
      state,
      action: PayloadAction<DashboardState["widgetResized"]>
    ) => {
      return {
        ...state,
        widgetResized: action.payload
      };
    }
  }
});

export const {
  setActiveDashboardId,
  showModalDeleteWidget,
  showModalHideWidget,
  updateDashboardSequence,
  setDashboards,
  setActivatedDashboardGroupName,
  setActivatedDashboardName,
  setSelectWidgetType,
  setWidgetResized
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
